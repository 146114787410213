import { defineComponent } from 'vue';
export default defineComponent({
    name: 'base-modal',
    emits: ['cancel', 'submit'],
    props: {
        show: Boolean,
        showDefaultCloseBtn: {
            type: Boolean,
            default: false,
        },
        showCancelBtn: {
            type: Boolean,
            default: true,
        },
        marginTop: {
            type: String,
            default: '15vh'
        },
        center: {
            type: Boolean,
            default: true,
        },
        hasActions: {
            type: Boolean,
            default: true,
        },
        disableButtonsWhenSubmit: Boolean,
        isLoadingSaveButton: Boolean,
        width: {
            type: String,
            default: '30vw',
        },
        setManualWidth: {
            type: Boolean,
            default: false,
        },
        buttonStretch: {
            type: Boolean,
            default: false,
        },
        submitButtonLabel: {
            type: String,
            default: 'Submit',
        },
        cancelButtonLabel: {
            type: String,
            default: 'Cancel',
        },
        customClass: {
            type: String,
            default: '',
        },
        disableSubmitButton: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        submit() {
            this.$emit('submit');
        }
    }
});
