<template>
    <el-dialog
      destroy-on-close
      center
      :modelValue="getOpenViewPropertyMapDialog"
      :append-to-body="true"
      :show-close="false"
      :before-close="beforeClose"
    >
    <AddressMapComponent :latlng="latlng" :property="property" :isModalMap="true"/>
  </el-dialog>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import AddressMapComponent from '@/modules/properties/components/AddressMapComponent.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';

export default defineComponent({
  name: 'property-map-modal',
  emits: ['on-close'],
  props: ['latlng', 'property'],
  components: {
    AddressMapComponent,
  },
  created() {
  },
  computed: {
    ...mapGetters(PROPERTY_STORE, ['getOpenViewPropertyMapDialog']),
  },
  methods: {
    beforeClose() {
      this.$emit('on-close');
    },
  }
});
</script>
<style lang="scss" scoped>
</style>
