<template>
  <div class="filter-container flex-row ai-c jc-sb show-in-mobile">
    <div class="filter-container__action-wrapper flex-row ai-c" v-if="viewType !== 'map'">
      <inline-svg class="verified-icon" :src="require(`@/assets/icons/filled-sort-icon.svg`)"></inline-svg>
      <p class="filter-container__sort-text default-text">Sort by</p>
    </div>
    <PageTitleWithCountComponent :title="title" :totalItemCount="count" v-if="viewType === 'map'" :viewType="viewType"/>
    <div class="flex-row ai-c" style="gap: 2rem;">
       <div class="filter-container__action-wrapper flex-row ai-c" v-if="showDeactivated">
        <inline-svg class="verified-icon" :src="require(`@/assets/icons/deactivate-icon.svg`)"></inline-svg>
        <p class="filter-container__action-text default-text">Deactivated</p>
      </div>
      <el-divider direction="vertical" style="margin: 0;"/>
      <div v-if="!disableTileView" class="filter-container__action-wrapper flex-row ai-c" :class="{'active': viewType === 'tile'}" @click="setView('tile')">
        <inline-svg class="verified-icon" :src="require(`@/assets/icons/tile-view-dark-icon.svg`)"></inline-svg>
        <p class="filter-container__action-text default-text">Tile view</p>
      </div>
      <div class="filter-container__action-wrapper flex-row ai-c" :class="{'active': viewType === 'map'}" @click="setView('map')" v-if="showMapMenu">
        <inline-svg class="verified-icon" :src="require(`@/assets/icons/map-view-dark-icon.svg`)"></inline-svg>
        <p class="filter-container__action-text">Map view</p>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitleWithCountComponent from '@/core/components/common/header/PageTitleWithCountComponent.vue';

export default {
  components: {
    PageTitleWithCountComponent
  },
  emits: ['set-view'],
  props: ['showAddButton', 'viewType', 'showDeactivated', 'title', 'count', 'showMapMenu', 'disableTileView'],
  setup(props, { emit }) {
    function setView(viewType) {
      emit('set-view', viewType);
    }

    return {
      setView,
    };
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.default-text {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  margin: 0;
  color:#0C0F4A;
}
.filter-container {
  // margin-top: 1rem;
  gap: 1.7rem;
  width: 100%;
  &__action-wrapper, &__add-action-wrapper {
    cursor: pointer;
    gap: .7rem;
  }
  &__action-wrapper:hover, &__action-wrapper.active {
    :deep(path) {
      fill: #FFA500;
      fill-opacity: 1;
    }
    .filter-container__action-text {
      color: #FFA500;
    }
  }
  &__sort-text {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.25px;
  }
  &__action-text {
    color: rgba(12, 15, 74, 0.5);
  }
  &__add-icon-wrapper {
    width: 30px;
    height: 30px;
    background: #FFA500;
    border-radius: 100%;
    box-sizing: border-box;
  }
  &__add-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
}

@media screen and (max-width: 1325px) {
  .filter-container {
    &__action-text, &__add-text {
      display: none;
    }
  }
}
</style>
