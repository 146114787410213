import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    name: 'default-dynamic-status-modal-content',
    emits: ['on-confirm', 'on-cancel'],
    props: ['content', 'loading'],
    // props: {
    //   show: Boolean,
    //   type: {
    //     type: String,
    //   },
    //   showHeader: {
    //     default: false,
    //     type: Boolean,
    //   },
    //   headerText: {
    //     type: String
    //   },
    //   hasTwoButton: {
    //     default: false,
    //     type: Boolean
    //   },
    //   buttonActiveLabel: {
    //     default: 'continue',
    //     type: String,
    //   },
    //   buttonInActiveLabel: {
    //     default: 'cancel',
    //     type: String,
    //   },
    //   icon: {
    //     /* eslint-disable global-require */
    //     default: `${require('@/assets/images/payment-successful-icon.svg')}`,
    //     /* eslint-disable global-require */
    //     type: String
    //   },
    //   statusText: {
    //     default: 'Payment Successful',
    //     type: String,
    //   },
    //   hasDescription: {
    //     default: true,
    //     type: Boolean,
    //   },
    //   descriptionText: {
    //     default: 'Thanks for the payment',
    //     type: String,
    //   },
    //   spanText: {
    //     default: 'Terms & Conditions',
    //     type: String,
    //   },
    // },
    data: () => {
        return {};
    },
    computed: {},
    methods: {
        confirm() {
            this.$emit('on-confirm');
        },
        cancel() {
            this.$emit('on-cancel');
        }
    }
});
