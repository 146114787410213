<template>
    <div class="page-title-container flex-row ai-c" :class="{'display-bottom': viewType === 'map'}">
        <p class="page-title-container__title default-text">{{title}}</p>
        <div class="page-title-container__count-wrapper flex-row ai-c jc-c">
          <p class="page-title-container__count default-text">
            {{totalItemCount}}
          </p>
        </div>
    </div>
</template>
<script>
export default {
  props: ['title', 'totalItemCount', 'viewType'],
};
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 28px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    margin: 0;
    color: #0C0F4A;
}
.page-title-container {
  &__count-wrapper {
    border: 1px solid #FAA100;
    border-radius: 16px;
    // padding: .5rem .9rem;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  &__count {
    font-size: 18px;
  }
}

@include media-md-max-width() {
  .page-title-container {
    position: absolute;
    top: -25px;
    left: 15px;
  }
  .page-title-container.display-bottom {
    position: relative;
    z-index: 36;
    top: 0;
    left: 0;
  }
}

@include media-sm-max-width() {
  .page-title-container {
    top: -53px;
    left: 25px;
  }
}

@include media-xs-max-width() {
  .page-title-container {
    top: -42px;
    &__count-wrapper {
      border-radius: 8px;
      padding: .2rem .6rem;
      box-sizing: border-box;
    }
    &__title {
      font-size: 16px;
    }
    &__count {
      font-size: 16px;
    }
  }
}
</style>
