<template>
    <section class="property-listing-container" v-if="hasMainLayoutInitialized" :class="{'map-view-mobile-layout': viewType === 'map'}">
        <div class="property-listing-header">
            <div class="property-listing-header__back-icon flex-row ai-c jc-c cursor-pointer show-in-mobile" v-if="viewType === 'map'" @click="setView('tile')">
              <el-icon :size="15" color="#0C0F4A"><ArrowLeftBold/></el-icon>
            </div>
            <SearchInputComponent @on-search="loadProperties($event)"/>
            <DisplayViewComponent
              :viewType="viewType"
              @set-view="setView"
              :showAddButton="canCreate"
              :showMapMenu="true"
              :showDeactivated="false"
              @add-function="routeToAddPropertyForm"/>
        </div>

        <!-- THIS MAP IS FOR MOBILE -->
        <div class="map-view-content show-in-mobile" v-if="viewType === 'map'" :class="{'full-map': isViewFullMap && viewType === 'map'}">
          <div class="map-view-content__expand-icon flex-row ai-c jc-c cursor-pointer hide-in-mobile"
            :class="{'condense-icon': isViewFullMap && viewType === 'map'}" @click="toggleViewMap">
            <el-icon :size="15" color="#0C0F4A" :class="{'hide': isViewFullMap}"><ArrowLeftBold/></el-icon>
            <el-icon :size="15" color="#0C0F4A" :class="{'hide': !isViewFullMap}"><ArrowRightBold/></el-icon>
            <p class="map-view-content__condense-text default-text" :class="{'hide': !isViewFullMap}"> View list</p>
          </div>
          <AddressMapComponent :latlng="[53.5809811, -0.6393335]" :addresses="addresses" @show-details="showDetails($event)"/>
        </div>
        <!-- THIS MAP IS FOR MOBILE -->

          <div class="property-listing-content"
            :class="{ 'flex-row-layout': viewType === 'map', 'changeMaxWidth': viewType === 'tile' }"
            v-if="properties.length > 0 && !initializing">
            <div class="property-listing-content__property-details-review-container show-in-mobile"
              v-if="viewType === 'map' && showPropertyDetails">
              <PropertyDetailsCard
                @handle-function-call="handleFunctionCall($event, selectedPropertyMobileView?.id)"
                @route-to-property-details="routeToPropertyDetails(selectedPropertyMobileView?.id)"
                :viewType="viewType"
                :property="selectedPropertyMobileView"
                @hide-property-details="hidePropertyDetails"
                :showCloseIcon="true"/>
            </div>
            <div class="property-listing-content__cards-container flex-column">
              <div class="property-listing-content__draggable-container flex-row ai-c jc-c show-in-mobile"
                :class="{'remove-top': collapseDrawer}" @click="collapseDrawer = !collapseDrawer">
                <div class="property-listing-content__drag-icon"></div>
              </div>
              <el-scrollbar :class="{ 'grid-layout': viewType === 'tile', 'hide': isViewFullMap && viewType === 'map', 'remove-margin-top': collapseDrawer }">
                <DisplayTypeComponentMobile
                  :viewType="viewType"
                  @set-view="setView"
                  :showDeactivated="true"
                  :showMapMenu="true"
                  title="Properties"
                  :count="totalProperties"/>
                <keep-alive>
                  <PropertyDetailsCard
                    @handle-function-call="handleFunctionCall($event, property.id)"
                    @route-to-property-details="routeToPropertyDetails(property.id)"
                    v-for="property in properties"
                    :key="property.id"
                    :viewType="viewType"
                    :property="property"
                    :showCloseIcon="false"/>
                </keep-alive>
              </el-scrollbar>
            </div>

            <!-- THIS MAP IS ONLY FOR IPAD AND DESKTOP VIEW -->
            <div class="map-wrapper hide-in-mobile" v-if="viewType === 'map'" :class="{'full-map': isViewFullMap && viewType === 'map'}">
              <div class="map-wrapper__expand-icon flex-row ai-c jc-c cursor-pointer hide-in-mobile"
                :class="{'condense-icon': isViewFullMap && viewType === 'map'}" @click="toggleViewMap">
                <el-icon :size="15" color="#0C0F4A" :class="{'hide': isViewFullMap}"><ArrowLeftBold/></el-icon>
                <el-icon :size="15" color="#0C0F4A" :class="{'hide': !isViewFullMap}"><ArrowRightBold/></el-icon>
                <p class="map-wrapper__condense-text default-text" :class="{'hide': !isViewFullMap}"> View list</p>
              </div>
              <AddressMapComponent :latlng="[53.5809811, -0.6393335]" :addresses="addresses" @show-details="showDetails($event)"/>
            </div>
            <!-- THIS MAP IS ONLY FOR IPAD AND DESKTOP VIEW -->
        </div>
        <Loader v-show="initializing"/>
        <NoPropertyComponent
          v-if="properties.length <= 0 && !initializing || properties.length === null && !initializing"/>

        <!-- MODAL -->
        <ConfirmDeletePropertyModal
          :show="confirmDeletePropertyModal"
          @on-confirm="onCancelModals"
          @on-cancel="onCancelModals"/>
        <ViewPropertyMapModal
          v-if="getOpenViewPropertyMapDialog"
          :latlng="selectedLatlng"
          :property="selectedProperty"
          @on-close="onClose"/>
    </section>
</template>
<script>
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';

import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { PAGE_LIMIT } from '@/core/constants';
import DisplayTypeComponentMobile from '@/modules/project-search/components/DisplayTypeComponentMobile.vue';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import AddressMapComponent from '@/modules/properties/components/AddressMapComponent.vue';
import ViewPropertyMapModal from '@/modules/property-details-children/components/modal/ViewPropertyMapModal.vue';
import ConfirmDeletePropertyModal from '@/modules/property-listing/components/modal/ConfirmDeletePropertyModal.vue';
import NoPropertyComponent from '@/modules/select-property/components/NoPropertyComponent.vue';
import PropertyDetailsCard from '@/modules/select-property/components/PropertyDetailsCard.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';

export default {
  components: {
    DisplayViewComponent,
    PropertyDetailsCard,
    DisplayTypeComponentMobile,
    NoPropertyComponent,
    Loader,
    ConfirmDeletePropertyModal,
    ViewPropertyMapModal,
    AddressMapComponent,
    ArrowLeftBold,
    ArrowRightBold,
    SearchInputComponent
  },

  data() {
    return {
      totalProperties: 0,
      properties: [],
      currentFilters: {
        page: 1,
        limit: PAGE_LIMIT,
        search: '',
      },
      initializing: false,
      confirmDeletePropertyModal: false,
      selectedProperty: null,
      selectedLatlng: null,
      isViewFullMap: false,

      addresses: [],
      collapseDrawer: false,
      showPropertyDetails: false,
      // isCollapse: false,
      selectedPropertyMobileView: null,
    };
  },

  mounted() {
    this.loadProperties(this.currentFilters);
  },

  computed: {
    ...mapGetters(PROPERTY_STORE, ['getOpenViewPropertyMapDialog', 'getIsMapView']),
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized', 'wsRole', 'isWsAdmin']),

    canCreate() {
      const { wsRole, isWsAdmin } = this;
      let show = true;

      if (wsRole && !isWsAdmin) {
        show = !show;
      }

      return show;
    },
  },

  methods: {
    ...mapActions(PROPERTY_STORE, ['getProperties', 'setOpenViewPropertyMapDialog', 'setIsMapView']),
    resize(action) {
      if (action === 'up') {
        this.isCollapse = true;
      } else {
        // setTimeout(() => {
        this.isCollapse = false;
        // }, 500);
      }
    },
    onCancelModals() {
      this.confirmDeletePropertyModal = false;
    },

    setLoaderFalse() {
      setTimeout(() => {
        this.initializing = false;
      }, 300);
    },

    async loadProperties(filters) {
      this.initializing = true;
      await this.getProperties(filters).then((response) => {
        const { data, totalCount } = response;
        this.totalProperties = totalCount;
        this.properties = this.normalizeData(data);
      });
      this.setLoaderFalse();
    },

    handleFunctionCall(event, id) {
      if (event && event?.name === 'routeToViewProperty') {
        this.setIsMapView(false);
        this.routeToPropertyDetails(id);
      }
      if (event && event?.name === 'deactivateProperty') {
        this.confirmDeletePropertyModal = true;
      }
      if (event && event?.name === 'viewMap') {
        this.selectedProperty = event?.property;
        this.selectedLatlng = event?.latlng;
        this.setOpenViewPropertyMapDialog(true);
      }
    },

    normalizeData(data) {
      let newData = [];

      newData = data.map((item) => {
        let latlang = null;

        if (item.addressData) {
          const address = JSON.parse(item.addressData);

          latlang = address?.latlng;

          this.addresses.push({
            address: item.address,
            uprn: item.uprn,
            latlang,
            id: item.id,
            postcode: item.postcode
          });
        }

        return { ...item, latlang };
      });

      return newData;
    },

    onClose() {
      this.setOpenViewPropertyMapDialog(false);
    },

    toggleViewMap() {
      this.isViewFullMap = !this.isViewFullMap;
    },

    setView(event) {
      if (event === 'map') {
        this.setIsMapView(true);
      } else {
        this.setIsMapView(false);
      }
      this.viewType = event;
    },

    showDetails(event) {
      this.selectedPropertyMobileView = this.properties.find((property) => property.id === event.id);
      this.showPropertyDetails = true;
    },

    hidePropertyDetails() {
      this.showPropertyDetails = false;
    }

  },

  setup() {
    const viewType = ref('list');
    const cardCount = ref(20);
    const router = useRouter();

    function routeToAddPropertyForm() {
      router.push({
        name: 'properties',
      });
    }

    const routeToPropertyDetails = async (id) => {
      router.push({
        name: 'property-details/home',
        params: { id }
      });
    };

    return {
      viewType,
      cardCount,
      routeToAddPropertyForm,
      routeToPropertyDetails
    };
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

:deep(.el-scrollbar__bar) {
    display: block !important;
}
:deep(.el-scrollbar__bar.is-vertical) {
    top: 0;
    right: 0;
    width: 6px !important;
}
.property-listing-container {
    margin: 0 20px;
    display:flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
    overflow: hidden;
    max-width: 1230px;
    position: relative;
}
.property-listing-header {
   width: 100%;
   display:flex;
   justify-content: space-between;
   align-items:center;
   gap: 1.3rem;

   &__back-icon {
      background: #FFF;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      gap:.7rem;
      min-width: 30px;
      // position: absolute;
      // z-index: 401;
      // top: 10px;
      // left: 10px;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
   }

   .title {
        font-family: Mulish;
        font-size: 28px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: left;
        color: #0C0F4A;
        display:flex;
        align-items: center;
        gap: 1rem;
        margin: 0;

        .count {
            font-family: Mulish;
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: center;
            color: #0C0F4A;
            border-radius: 16px;
            border: 1px solid #FAA100;
            padding: .5rem 1rem;
        }
   }
}

.property-listing-content {
    height: calc(100% - 4rem);

    &__cards-container {
      height: 100%;
    }
    .el-scrollbar {
        :deep(.el-scrollbar__view) {
            display:flex;
            flex-direction: column;
            gap: 1.5rem;
            height: auto !important;
            padding-right: .7rem;
            padding-bottom: 2rem;
            transition: display 0.5s ease-in-out;
            -webkit-transition: display 0.5s ease-in-out;
            -moz-transition: display 0.5s ease-in-out;
            -ms-transition: display 0.5s ease-in-out;
            -o-transition: display 0.5s ease-in-out;
        }
    }
    .el-scrollbar.grid-layout {
        :deep(.el-scrollbar__view) {
            display:grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }
    }

    .map-wrapper {
        width: 70%;
        border-radius: 16px;
        overflow-y: scroll;
        height: calc(100% - 4rem);
        position: relative;
        transition: width 0.5s ease-in-out;
        -webkit-transition: width 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out;
        -ms-transition: width 0.5s ease-in-out;
        -o-transition: width 0.5s ease-in-out;
        img {
            width: 100%;
            border-radius: 16px;
            object-fit: cover;
        }

        &__expand-icon {
          background: #FFF;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          gap:.7rem;
          position: absolute;
          z-index: 401;
          top: 10px;
          left: 10px;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
          box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
        }
        &__expand-icon.condense-icon {
          width: 119px;
          border-radius: 100px;
        }
        &__condense-text {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.4000000059604645px;
        }
    }
    .map-wrapper.full-map {
      width: 100%;
    }
}

.el-input {
    width: 100%;
    min-width: 320px;
    max-width: 480px;
    height: 42px;
    border-radius: 30px;
    :deep(.el-input__inner) {
      background: rgba(12, 15, 74, 0.05);
      border: none;
      color: #111;
      border-radius: 30px;
      font-size: 14px;
      padding: 20px 20px 20px 40px;
      font-family: Arial;
  }

  :deep(.el-input__prefix) {
      left:10px;
      color:#111;
  }
}

.property-listing-content.flex-row-layout {
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
}

.property-listing-content.changeMaxWidth {
  max-width: 1230px;
}

.hide {
  display: none;
}

@include  media-md-max-width() {
  .property-listing-content {
    height: calc(100% - 4rem);

    .el-scrollbar {
      :deep(.el-scrollbar__view) {
        padding-bottom: 2rem;
      }
    }
  }
}
@include media-sm-max-width() {
  :deep(.leaflet-popup) {
    display: none;
  }
  :deep(.el-scrollbar__bar) {
      display: none !important;
  }
  .property-listing-container {
    .property-listing-content {
      .el-scrollbar {
        :deep(.el-scrollbar__view) {
          padding-right: 0;
        }
      }
    }
  }
  .map-view-mobile-layout {
    margin: 0;
    .property-listing-header {
      width: calc(100% - 2.4rem);
      position: relative;
      z-index: 5;
      margin: 20px;
      .el-input {
        box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
      }
      :deep(.el-input__inner) {
        background: #FFF;
      }
    }
    .property-listing-content {
      overflow-y: auto;
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      &__property-details-review-container {
        position: fixed;
        z-index: 1;
        bottom: 11em;
        // bottom: 14em;
        padding: 1rem;
      }
      &__cards-container {
        width: 100%;
        gap: 0;
        position: relative;
      }
      &__draggable-container {
        position: absolute;
        z-index: 3;
        top: 61vh;
        width: 100%;
        height: 50px;
        transition: top .3s ease-in-out;
      }
      &__drag-icon {
        width: 40px;
        height: 5px;
        display: block;
        border-radius: 100px;
        background: rgba(12, 15, 74, 0.4);
      }
      .el-scrollbar {
        position: absolute;
        margin-top: 61vh;
        background: #F1F6FB;
        z-index: 2;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        transition: margin-top 0.3s ease-in-out;
        &::before {
          content: '';
          width: 100%;
          height: 50px;
          display: block;
          background: #F1F6FB;
        }

        // &::after {
        //   content: '';
        //   width: 40px;
        //   height: 5px;
        //   display: block;
        //   position: absolute;
        //   top: 17px;
        //   left: 45%;
        //   border-radius: 100px;
        //   background: rgba(12, 15, 74, 0.4);

        //   // border: solid rgba(12, 15, 74, 0.4);
        //   // border-width: 0 4px 4px 0;
        //   // display: inline-block;
        //   // padding: 5px;
        //   // transform: rotate(-135deg);
        //   // -webkit-transform: rotate(-135deg);
        //   transition: transform .3s ease-in-out;
        // }
        :deep(.el-scrollbar__view) {
          padding: 0 20px 5rem 20px;
        }
      }
      .el-scrollbar.remove-margin-top {
        margin-top: 0;

        // &::after {
        //   transform: rotate(45deg);
        //   -webkit-transform: rotate(45deg);
        //   top: 13px;
        // }
      }
      .map-wrapper {
        height: 90%;
        position: fixed;
        width: 100%;
        top: 0;
      }

      &__draggable-container.remove-top {
        top: 0;
        position: sticky;
      }
    }
    .property-listing-content.add-margin-top {
      margin-top: 0;

      .el-scrollbar {
        margin-top: 61vh;
      }
    }
  }
  .map-view-content {
    height: 90%;
    position: fixed;
    width: 100%;
    z-index: 1;

    &__expand-icon {
      background: #FFF;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      gap:.7rem;
      position: absolute;
      z-index: 401;
      top: 10px;
      left: 10px;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
    }
    &__expand-icon.condense-icon {
      width: 119px;
      border-radius: 100px;
    }
    &__condense-text {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.4000000059604645px;
    }
  }
}
@include media-xs-max-width() {
  .property-listing-container {
    height: 100%;
    gap: .7rem;
  }
  .property-listing-content {
    height: calc(100% - 3.3rem);
  }
  .el-input {
      min-width: 220px;
      max-width: 320px;
  }
}
</style>
