import { Search } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { PAGE_LIMIT } from '@/core/constants';
const __default__ = defineComponent({
    components: {
        Search
    },
    emits: ['on-search'],
    props: {
        isSmall: Boolean,
        noHide: Boolean,
        minWidth: {
            type: String,
            default: '320px',
        },
        maxWidth: {
            type: String,
            default: '420px'
        }
    },
    data() {
        return {
            keyword: '',
            currentFilters: {
                page: 1,
                limit: PAGE_LIMIT,
                projectJobStatusIds: [],
            }
        };
    },
    watch: {
        keyword: {
            immediate: true,
            deep: true,
            handler(keyword) {
                if (keyword) {
                    this.onSearch(encodeURIComponent(keyword));
                }
            }
        }
    },
    methods: {
        onSearch(keyword) {
            const { currentFilters } = this;
            currentFilters.search = keyword;
            this.$emit('on-search', currentFilters);
        },
    }
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "64aa8434": (_ctx.$props.minWidth),
        "62edbf3c": (_ctx.$props.maxWidth)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
