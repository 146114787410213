<template>
    <div class="filter-container flex-row ai-c jc-fe">
        <div class="filter-container__action-wrapper flex-row ai-c hide-in-mobile" v-if="showDeactivated">
          <inline-svg class="verified-icon" :src="require(`@/assets/icons/deactivate-icon.svg`)"></inline-svg>
          <p class="filter-container__action-text default-text">Deactivated</p>
        </div>
        <div class="filter-container__action-wrapper flex-row ai-c hide-in-mobile" v-if="showListMenu" :class="{'active': viewType === 'list'}" @click="setView('list')">
          <inline-svg class="verified-icon" :src="require(`@/assets/icons/list-view-dark-icon.svg`)"></inline-svg>
          <p class="filter-container__action-text default-text">List view</p>
        </div>
        <div v-if="!disableTileView" class="filter-container__action-wrapper flex-row ai-c hide-in-mobile" :class="{'active': viewType === 'tile'}" @click="setView('tile')">
          <inline-svg class="verified-icon" :src="require(`@/assets/icons/tile-view-dark-icon.svg`)"></inline-svg>
          <p class="filter-container__action-text default-text">Tile view</p>
        </div>
        <div class="filter-container__action-wrapper flex-row ai-c hide-in-mobile" :class="{'active': viewType === 'map'}" @click="setView('map')" v-if="showMapMenu">
          <inline-svg class="verified-icon" :src="require(`@/assets/icons/map-view-dark-icon.svg`)"></inline-svg>
          <p class="filter-container__action-text default-text">Map view</p>
        </div>
        <el-popover placement="bottom-end" width="auto" trigger="click" v-if="showSort">
          <div class="options flex-column">
            <el-radio-group v-model="sortData" @change="onSort">
              <el-radio :label="item?.name" v-for="(item, index) in popOverData" :key="index">{{ item.name }}</el-radio>
            </el-radio-group>
          </div>
          <template #reference>
            <div class="filter-container__action-wrapper flex-row ai-c hide-in-mobile">
              <inline-svg class="verified-icon" :src="require(`@/assets/icons/filled-sort-icon.svg`)"></inline-svg>
              <p class="filter-container__sort-text default-text">Sort by</p>
            </div>
          </template>
        </el-popover>
        <div class="filter-container__add-action-wrapper flex-row ai-c" v-if="showAddCta" @click="add">
          <div class="filter-container__add-icon-wrapper flex-row ai-c jc-c">
            <el-icon :size="13" color="#FFF"><Plus/></el-icon>
          </div>
           <p class="filter-container__add-text default-text">{{ actionText }}</p>
        </div>
    </div>
</template>
<script>

import { Plus } from '@element-plus/icons';
import { computed } from 'vue';

export default {
  components: {
    Plus,
  },
  emits: ['set-view', 'add-function', 'sort'],
  props: {
    showAddButton: Boolean,
    viewType: String,
    showDeactivated: Boolean,
    showMapMenu: Boolean,
    disableTileView: Boolean,
    actionText: {
      type: String,
      default: 'New'
    },
    showSort: Boolean,
    popOverData: {
      type: Array,
      default: () => []
    },
    showListMenu: {
      type: Boolean,
      default: true
    },
    showTileMenu: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const showAddCta = computed(() => {
      return props.showAddButton;
    });

    function setView(viewType) {
      emit('set-view', viewType);
    }

    function add() {
      emit('add-function');
    }

    return {
      add,
      setView,
      showAddCta,
    };
  },

  data() {
    return {
      sortData: '',
    };
  },

  methods: {
    onSort() {
      this.$emit('sort', this.sortData);
    }
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.default-text {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  margin: 0;
  color:#0C0F4A;
}
.filter-container {
  gap: 1.7rem;
  &__action-wrapper, &__add-action-wrapper {
    cursor: pointer;
    gap: .7rem;
  }
  &__action-wrapper:hover, &__action-wrapper.active {
    :deep(path) {
      fill: #FFA500;
      fill-opacity: 1;
    }
    .filter-container__action-text {
      color: #FFA500;
    }
  }
  &__sort-text {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.25px;
  }
  &__action-text {
    color: rgba(12, 15, 74, 0.5);
    white-space: nowrap;
  }
  &__add-icon-wrapper {
    width: 30px;
    height: 30px;
    background: #FFA500;
    border-radius: 100%;
    box-sizing: border-box;
  }
  &__add-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
}

@media screen and (max-width: 1325px) {
  .filter-container {
    &__action-text, &__add-text {
      display: none;
    }
  }
}
</style>
